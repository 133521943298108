<template>
    <main>
        <loading
            :active="isLoading"
            :can-cancel="false"
            :on-cancel="onCancel"
            :is-full-page="fullPage"
        />
        <HeadShop :showButtonPhotoProfile='false' />

        <section class="section pt-0 my-status">
            <div class="container">
                <p class="breadcrumb">
                    <a href="/">
                        <span class="icon-home-outline"></span>
                    </a> /
                    <span>Registro usuario</span>
                    
                </p>
                <h2>
                    <a href="#" @click="router.go(-1)">
                        <img src="/images/arrowleft.svg" alt="Club de Sastreria Barrington" />
                    </a> Solicitud de registro exitoso
                </h2>
                                
                <p class="form__success">
                    <template v-if="store.state.registerUserSuccess.only_have_phone_movil">
                        Se te enviará un mensaje a tu nro {{ store.state.registerUserSuccess.phone_movil }} cuando se apruebe tu registro.
                    </template>
                    <template v-else>                    
                        Se te enviará un mensaje a tu correo electrónico {{ store.state.registerUserSuccess.email }} cuando se apruebe tu registro
                    </template>
                </p>
            </div>
        </section>
    </main>
</template>

<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import { ref } from "vue";
import API from "@/api";
import router from "@/router";
import store from "@/store";
import HeadShop from "@/views/private/shop/components/HeadShop";

export default {
    name: "RegisterUser",
    components: {
        HeadShop,
        Loading
    },
    setup() {
        var isLoading = ref(false);
        const fullPage = true;

        const dataShop = ref({
            name: "",
            country: "",
            department: "",
            address: ""
        });
        const formRegisterUser = ref({
            type_user: null,
            first_name: "",
            last_name: "",
            document_code: "",
            email: "",
            username: "demo1",
            phone_movil: "",
            only_have_phone_movil: false,
            shop_that_registered: store.state.dataToken.shop //tienda que lo registro
        });
        const typeUser = ref([]);
        const messageError = ref({ errors: {} });
        const showSuccessRegisterUser = ref(Boolean);

        showSuccessRegisterUser.value = false;

        const getDataShop = () => ({
            name: localStorage.getItem("name"),
            country: localStorage.getItem("country"),
            department: localStorage.getItem("department"),
            address: localStorage.getItem("address")
        });

        dataShop.value = getDataShop();

        const getTypeUser = async () => {
            await API.get("/api/type-user")
                .then(response => {
                    typeUser.value = response.data;
                })
                .catch(error => {
                    console.log(error.response.data);
                });
        };

        const onSubmit = () => {
            isLoading.value = true;
            API.post("/api/user", formRegisterUser.value)
                .then(response => {
                    isLoading.value = false;
                    showSuccessRegisterUser.value = true;
                })
                .catch(error => {
                    isLoading.value = false;
                    messageError.value = error.response.data;
                });
        };

        return {
            store,
            isLoading,
            fullPage,
            dataShop,
            formRegisterUser,
            typeUser,
            getTypeUser,
            onSubmit,
            messageError,
            showSuccessRegisterUser
        };
    },
    mounted() {
        this.getTypeUser();
    }
};
</script>